<template>
  <div
    :class="className"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="options-menu-button"
    tabindex="-1"
    v-show="value"
  >
    <div class="py-1" v-for="(row, row_index) in menu" :key="`row-${row_index}`">
      <a
        href="#"
        class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200"
        role="menuitem"
        tabindex="-1"
        id="options-menu-item-0"
        v-for="(item, col_index) in items(row)"
        :key="`item-${col_index}`"
        @click.prevent="() => updateContext(item)"
        >{{ item.label }}</a
      >
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'ContextMenu',
  props: {
    value: {
      default: false,
    },
    menu: {
      required: true,
    },
    className: {
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    updateContext(item) {
      if (item.callback) item.callback()

      if (item.to) this.$router.push(item.to)

      this.$emit('input', !this.value)
    },
    items({ items }) {
      return _.filter(items, item => (item.permission ? this.can(item.permission) : item))
    },
  },
}
</script>

<style></style>
